import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TelegramLoginWidgetComponent } from "../../entry/auth/login/telegram-login/telegram-login-widget.component";

@Component({
  selector: 'hb-login-modal',
  standalone: true,
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  imports: [MatIconModule, TelegramLoginWidgetComponent]
})
export class LoginModalComponent implements OnInit {
  public returnUrl: string;

  constructor(
    private dialogRef: MatDialogRef<LoginModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogDataReturnUrl: string
  ) { }

  public ngOnInit(): void {
    this.returnUrl = this.dialogDataReturnUrl;
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }
}
