<header class="top-bar flex-row justify-center" role="banner">
    <button class="close-icon cursor-pointer" (click)="onCancel()" aria-label="Close">
        <mat-icon>close</mat-icon>
    </button>
</header>
<section class="confirmation-container">
    <p i18n>In order to join you should be registered and logged in</p>
    <p class="info-text" i18n>After log in you can try to join again</p>
</section>
<footer class="flex-col align-center mb-10">
    <hb-telegram-login-widget [returnUrl]="returnUrl" (loginSuccess)="onCancel()"></hb-telegram-login-widget>
</footer>
